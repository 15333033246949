.cursor-pointer {
  cursor: pointer;
}

.d-flex {
  display: flex;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-end {
  justify-content: flex-end;
}

.align-items-center {
  align-items: center;
}

.align-items-end {
  align-items: flex-end;
}

.text-align-justify {
  text-align: justify;
}

.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 2;
}

.flex-3 {
  flex: 3;
}

.text-center {
  text-align: center;
}

.h-100 {
  height: 100%;
}

.w-25 {
  width: 25%;
}

.w-50 {
  width: 50%;
}

.w-75 {
  width: 75%;
}

.w-100 {
  width: 100%;
}

.pt-0 {
  padding-top: 0;
}

.pt-1 {
  padding-top: $size8;
}

.pt-2 {
  padding-top: $size16;
}

.pt-3 {
  padding-top: $size24;
}

.pt-4 {
  padding-top: $size32;
}

.pt-5 {
  padding-top: $size40;
}

.pb-0 {
  padding-bottom: 0;
}

.pb-1 {
  padding-bottom: $size8;
}

.pb-2 {
  padding-bottom: $size16;
}

.pb-3 {
  padding-bottom: $size24;
}

.pb-4 {
  padding-bottom: $size32;
}

.pb-5 {
  padding-bottom: $size40;
}

.pl-0 {
  padding-left: 0;
}

.pl-1 {
  padding-left: $size8;
}

.pl-2 {
  padding-left: $size16;
}

.pl-3 {
  padding-left: $size24;
}

.pl-4 {
  padding-left: $size32;
}

.pl-5 {
  padding-left: $size40;
}

.pr-0 {
  padding-right: 0;
}

.pr-1 {
  padding-right: $size8;
}

.pr-2 {
  padding-right: $size16;
}

.pr-3 {
  padding-right: $size24;
}

.pr-4 {
  padding-right: $size32;
}

.pr-5 {
  padding-right: $size40;
}

.mt-0 {
  margin-top: 0;
}

.mt-1 {
  margin-top: $size8;
}

.mt-2 {
  margin-top: $size16;
}

.mt-3 {
  margin-top: $size24;
}

.mt-4 {
  margin-top: $size32;
}

.mt-5 {
  margin-top: $size40;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-1 {
  margin-bottom: $size8;
}

.mb-2 {
  margin-bottom: $size16;
}

.mb-3 {
  margin-bottom: $size24;
}

.mb-4 {
  margin-bottom: $size32;
}

.mb-5 {
  margin-bottom: $size40;
}

.ml-0 {
  margin-left: 0;
}

.ml-1 {
  margin-left: $size8;
}

.ml-2 {
  margin-left: $size16;
}

.ml-3 {
  margin-left: $size24;
}

.ml-4 {
  margin-left: $size32;
}

.ml-5 {
  margin-left: $size40;
}

.mr-0 {
  margin-right: 0;
}

.mr-1 {
  margin-right: $size8;
}

.mr-2 {
  margin-right: $size16;
}

.mr-3 {
  margin-right: $size24;
}

.mr-4 {
  margin-right: $size32;
}

.mr-5 {
  margin-right: $size40;
}

.mh-100px {
  min-height: 100px;
}

// Typography
.font-size-4 {
  font-size: $size4;
}
.font-size-8 {
  font-size: $size8;
}
.font-size-10 {
  font-size: $size10;
}
.font-size-12 {
  font-size: $size12;
}
.font-size-14 {
  font-size: $size14;
}
.font-size-16 {
  font-size: $size16;
}
.font-size-20 {
  font-size: $size20;
}
.font-size-24 {
  font-size: $size24;
}
.font-size-32 {
  font-size: $size32;
}
.font-size-36 {
  font-size: $size36;
}
.font-size-40 {
  font-size: $size40;
}
.text-semibold {
  font-weight: 600;
}
.text-bold {
  font-weight: bold;
}
.text-italic {
  font-style: italic;
}
.text-underline {
  text-decoration: underline;
}
.text-white-space {
  white-space: nowrap;
}

//colors
.color-purple-1 {
  color: $purple1;
}
.color-purple-2 {
  color: $purple2;
}
.color-purple-3 {
  color: $purple3;
}
.color-grey-1 {
  color: $grey1;
}
.color-grey-2 {
  color: $grey2;
}
.color-grey-3 {
  color: $grey3;
}

.color-grey-4 {
  color: $grey4;
}

.color-black {
  color: $black;
}

.color-blue-2 {
  color: $blue2;
}