.ant-tooltip {
  max-width: 100% !important;
}

.ant-tooltip-arrow-content {
  background-color: $white !important;
  --antd-arrow-background-color: unset !important;
}

.ant-tooltip-inner {
  text-align: center !important;
  font-size: $size12;
  line-height: $size18;
  padding: $size8 $size12 !important;
  white-space: nowrap;
  border-radius: $size8 !important;
  background-color: $white !important;
  color: $greyDark1 !important;
}


.ant-btn.application_btn.export_btn
 {
  background-color: #1782D5!important;
  border: 1px solid #bdbdbd;
  position: absolute; 
  right: 10px;
  color: #ffffff;
  font-weight: 500;
  margin-top:1rem;
  margin-bottom:1rem;
}
 