// Typography
$size1: 0.0625rem;
$size2: 0.125rem;
$size4: 0.24rem;
$size5: 0.3125rem;
$size6: 0.375rem;
$size7: 0.4375rem;
$size8: 0.5rem;
$size9: 0.5625rem;
$size10: 0.625rem;
$size11: 0.6875rem;
$size12: 0.75rem;
$size14: 0.875rem;
$size15: 0.9375rem;
$size16: 1rem;
$size18: 1.125rem;
$size20: 1.25rem;
$size22: 1.375rem;
$size24: 1.5rem;
$size28: 1.75rem;
$size32: 2rem;
$size36: 2.25rem;
$size37: 2.3125rem;
$size40: 2.5rem;
$size42: 2.625rem;
$size44: 2.75rem;
$size52: 3.25rem;
$size56: 3.5rem;
$size60: 3.75rem;
$size64: 4rem;
$size93: 5.8125rem;
$size100: 6.25rem;
$size126: 7.875rem;
$size164: 10.25rem;
$size180: 11.25rem;
$size184: 11.5rem;
$size200: 12.5rem;
$size216: 13.4375rem;
$size220: 13.75rem;
$size250: 15.625rem;
$size256: 16rem;
$size288: 18rem;
$size308: 19.25rem;
$size335: 20.9375rem;
$size400: 25rem;
$size403: 25.1875rem;
$size500: 31.25rem;
$size1118: 69.875rem;

// Colors
$grey1: #f5f2ef;
$grey2: #d5d0c9;
$grey3: #d9d9d9;
$grey4: #4f4f4f;
$grey5: #bdbdbd;
$grey6: #c4c4c4;
$grey7: #e4e7ec;
$grey8: #667085;
$grey9: #e0e0e0;
$grey10: #828282;
$grey11: #fbf8ff;
$grey12: #fffbfa;
$grey13: #d0d5dd;
$grey14: #9f9a93;
$grey15: #6f6f6f;
$grey16: #f1f0f3;
$grey17: #f5f3f2;
$grey18: #cbc4bc;
$grey19: #878178;
$grey20: #dbd9d8;
$grey21: #543f3f;
$grey22: #d1cbc5;
$grey23: #101828;
$grey24: #ada8a2;
$grey25: #f2f2f2;
$grey26: #101828;
$grey27: #b8b0a7;
$grey28: #eae7e4;
$grey29: #e0dcd7;
$grey30: #b5aca1;
$grey31: #b5aca0;
$grey32: #9f9284;
$grey33: #344054;
$grey34: #f5f5f5;
$purple1: #4f2d7f;
$purple2: #baa1de;
$purple3: #725798;
$purple4: #6941c6;
$purple5: #bf91fe;
$purple6: #f5edff;
$purple7: #f4ebff;
$purple8: #f9f5ff;
$purple9: #9581b2;
$purple10: #fcfaff;
$black: #000000;
$white: #ffffff;
$blue1: #2d56ea;
$red1: #cc152d;
$red2: #e92841;
$red3: #f3aeb7;
$red4: #f6a9b3;
$red5: #f04438;
$red6: #fda29b;
$brown1: #383029;
$brown2: #33302c;
$brown3: #836666;
$greyDark1: #344054;
$greyDark2: #3b3b3b;
$blue1: #003375;
$blue2: #0065b3;
$blue3: #1782d4;
$blue4: #2d97e9;
$blue5: #70b7ee;
$blue6: #e1e8f5;
$blue7: #f5faff;
$blue8: #2547a1;
$blue9: #e9f5ff;
$blue10: #F1F2FF;
$blue11: #003476;
$grey35: #f8fbff;
$grey36: #F7F8F8;
$green1: #00703c;
