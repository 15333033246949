.app-container {
  height: 100vh;
  width: 100%;
  box-sizing: border-box;
  display: block;
}

.ant-layout-header {
  padding: unset!important;
}

.app-layout {
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #fff !important;

  &__header {
    background-color: $blue1 !important;
    color: $blue2 !important;
    height: auto !important;
    border-bottom: 8px solid #046ec5!important;
    //padding: $size28 $size200 !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
    }

    &__logo {
      margin-left: $size32;
      width: $size180;
      cursor: pointer;
    }

    &__logo-gr {
      margin-top: $size10;
      margin-right: $size12;
      width: $size164;
    }

    &__buttons {
      display: flex;
      & > button:first-child {
        margin-right: $size20;
      }
    }

    &__content {
      padding: $size60 $size200;
      & > div {
        height: 100%; 
        display: flex;
        min-height: calc(62vh);  
        flex-direction: column;
        //justify-content: space-between;
      }
    }
}

.ant-layout {
  background: unset!important;
  min-height: 100%!important;
}

.app-layout__header_landing {
  background-color: #003476 !important;
  align-items: center;
  height: 100px !important;
  //padding: $size28 $size180 !important;
  width: 100%;
  display: flex;
}

.app-layout__sub_header_landing {
  background-color:  #F5F5F5 !important;
  align-items: center;
  height: 100px !important;
  border-bottom: 1px solid rgba(255, 255, 255, 0.59);
  padding: $size28 $size180 !important;
  width: 100%;
  display: flex;

  .header-right {
    display: flex;
    justify-content: end;
    .title {
      font-size: 2.3rem;
      font-weight: 400!important;
      color: #003476;
    }
  }

}

.header-left {
  display: flex;
  justify-content: space-between;
  margin-right: 3rem;
}

.header-right {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  // margin-right: 5rem;
  .header-links {
    display: flex;
    flex-direction: row;
    font-size: 1rem;
    margin-right: 19.688rem;
  }

  .header-link a {
    color: #FFFFFF;
    margin: 10px;
    text-decoration: none;
  }

  .header-link a:hover{
    color: #afafaf!important;
    text-decoration: none;
  }
}

.header-icon-mobile {
  display: none;
}

.header-links-mobile {
  position: absolute;
  left: 0px;
  top: 100px;
  width: 100%;
  z-index: 1;
  background-color: #003375;
  display: flex;
  flex-direction: column;
  padding: 2rem;

  .header-paragraph {
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    width: 100%;
    p {
      line-height: 2rem;  
    }

    .contact-form-link{
      display: flex;
      justify-content: center;
      font-style: italic;
    }

    a {
      color: #FFFFFF;
    }
    
     a:hover{
      color: #afafaf!important;
      text-decoration: none;
    }
  }

  .header-link {
    color: #FFFFFF;
    margin-left: 3rem;
    line-height: 2.5rem;
    ul li {
      list-style-type: none;
      margin-left: 1.5rem;
    }
  }

  .header-link a{
    margin-left: -0.1rem;
    color: #FFFFFF;
    text-decoration: none;
  }

  .header-link a:hover{
    color: #afafaf!important;
    text-decoration: none;
  }
}

.ant-menu {
  background: #003375!important;
}

.ant-menu-submenu, .ant-menu-item {
  color: #FFFFFF; 
}
.ant-menu-sub {
  border-top: 5px solid #0065B3;
  background: #003375!important;
  color: #FFFFFF; 
}

.ant-menu-inline, .ant-menu-vertical, .ant-menu-vertical-left {
  border-right: unset!important;
}

.ant-menu-submenu-popup {
  z-index: 10000!important;
}

.ant-menu-item a {
  color: #FFFFFF!important;
  text-decoration: none!important;

  &:hover {
    color: #afafaf!important;
  }
}

.ant-menu-item-selected a, .ant-menu-item-selected a:hover{
  color: unset!important;
}

.ant-menu-item-selected {
  color: #FFFFFF!important;
  background: unset!important;
}

.ant-menu-vertical .ant-menu-submenu-selected, .ant-menu-vertical-left .ant-menu-submenu-selected, .ant-menu-vertical-right .ant-menu-submenu-selected {
  color: #FFFFFF!important;;
}

.ant-tooltip .ant-tooltip-content .ant-tooltip-arrow .ant-tooltip-arrow-content{
  display: none;
}

.ant-menu-submenu-expand-icon, .ant-menu-submenu-arrow {
  color: #FFFFFF!important;
  transform: rotate(90deg)!important;
}

.ant-menu-title-content:hover {
  color: #afafaf!important;
}

.header-tooltip-operator {
  margin-top: -0.7rem!important;
}

.operator{
  display: flex;
  align-items: center;
  justify-content: center;

  & .operator-downoutline {
    font-size: 0.7rem;
    color: #fff;
    margin-left: 0.2rem;
  }

  &:hover {
      filter: invert(30%) ;
  }
}

.header-link-program {
  &:hover{
  color: #afafaf!important;
  cursor: pointer;
}
}

.program-downoutline {
  color: #FFFFFF!important;
  font-size: 0.8rem;

  &:hover {
    color: #afafaf!important;
  }
}

.ant-tooltip .ant-tooltip-content .ant-tooltip-inner {
  border-top: 5px solid #0065B3;
  background-color: #003375!important;
  color: #FFFFFF!important;
  padding: 2rem!important;

  .header-tooltip-operator-left {
    width: 32rem;
    height: 18rem;  
  }

  .header-tooltip-operator-left-span {
    float:left;
    width:10%;
    line-height: 1.6rem;

    a {
      font-style: italic;
    }

    a:nth-last-child(1) {
      font-style: unset;
    }
  }

  span {
    font-size: 1rem;
    
    a {
      color: #FFFFFF;
      font-weight: bold;
      line-height: 2rem;
    }
  
    a:hover{
      color: #afafaf!important;
      text-decoration: none;
    }
  }
}

.app-layout__logo-gr-democracy {
  margin-right: 2rem;
  cursor: pointer;
}

.app-layout__logo-gr-2-0 {
  margin-top: $size20;
  cursor: pointer;
}

.app-layout__ministry-env-energy {
  margin-top: $size32;
}

.app-layout__logo-digital {
  transform: scale(130%);
  cursor: pointer;
}

.app-layout__logo-ktp {
  transform: scale(110%);
  cursor: pointer;
}

.ktp_logo, .digital_logo {
  cursor: pointer;
}

.badges {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
}

.badges-content {
  display: flex;
  background-color: #003375;
  color: #FFFFFF!important;
  border-radius: 16px!important;
  font-weight: 400;
  padding: 4px 10px 4px 12px;
  height: fit-content!important;
  width: fit-content!important;
  margin-right: 6px;
  margin-top: 6px;
  gap: 4px;

  a {
    color: #FFFFFF!important;
  }

  
  a.active-page {
    color: #afafaf!important;
  }

  a:link { 
    text-decoration: none;
    color: #FFFFFF!important;
  }

  a:hover { 
    text-decoration: none;
    color: #FFFFFF!important;
  }
  a:active { 
    text-decoration: none;
    color: #FFFFFF!important;
  }
}

.main-content {
  width: 80% !important;
  height: 100%;
  background: white!important;;
  align-items:center;
  margin: 2rem auto;
  margin-bottom: 15rem !important;

  &__collapse {
    border: unset!important;
    border-radius: 9px!important;
    margin-top: 10px!important;
  }

  .ant-collapse-header {
    font-weight: 400;
    font-size: 1.5rem;
    line-height: 1.5rem;
    // background: #1890ff;
    background: #1782D5;
    border-radius: 38px !important;
  }

  .header-blue{
    color: #1782D5;
    margin-top: 2rem;
  }

  .ant-collapse {
    font-size: 1rem;
    font-weight: 400;
    background-color: #FFFFFF;
  }

  .ant-collapse-header {
    padding: 17px 40px 16px 18px !important;
  }

  .ant-collapse-item {
    border-bottom: unset!important;
  }

  .ant-collapse-content {
    border-radius: 8px!important;
  }

  .ant-collapse-item-active {
    border: 1px solid #BDBDBD!important;
    border-radius: 8px!important;

    & .ant-collapse-header {
      background: #FFFF;
    }

    & .ant-collapse-content {
      border-top: unset;
    }
  }

  &__table  {
    & .ant-table-content {
      border-radius: 8px!important;
      border: 1px solid #1782D5;
    }

    // & .ant-table-thead {
    //   background: #1782D5;
    // }
  
    & .ant-table-thead {
      & tr th {
      font-weight: 700;
      font-size: 0.75rem !important;
      line-height: 1.5rem !important;
      background: #1782D5!important;
      color: #FFFFFF;
    }}

    & .ant-table-thead tr th:first-child{
      border-radius: 6px 0px 0px 0px!important;
    }

    & .ant-table-thead tr th:last-child{
      border-radius: 0px 6px 0px 0px!important;
    }

    & .ant-table-tbody tr:last-child td:first-child{
      border-radius: 0px 0px 0px 7px!important;
    }

    & .ant-table-tbody tr:last-child td:last-child{
      border-radius: 0px 0px 7px 0px!important;
    }
  }
}

.collapse-header {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  text-align: center;
  word-wrap: break-word;
  margin-bottom: 6rem;
  margin-top: 7rem;
  gap: 4.063rem;

  h1 {
    font-size: 1.875rem;
  }

  & > .ant-btn {
    padding-bottom: 55px;
    padding-top: 20px;
    font-size: 1.875rem;
    border-radius: 38px !important;
    line-height: normal;
    width: 28.5rem !important;

    & span {
      margin: auto;
    }
  }
}

.documents-header {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #dcdcdc;
  height: 8rem;
  font-size: 1.875rem;
  font-weight: 700;
}

.documents-items {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 2rem;
  font-size: 1rem;
  
  .docs {
    width: 100%;
    text-align: center;
  }

  .docs:nth-child(2){
    margin: 0 2rem;
  } 
}

.documents-category {
  display: flex;
  justify-content: center;
  background: #1782D5;
  color: #FFFFFF;
  height: 5rem;
  align-items: center;
  margin-bottom: 1.5rem;
  font-weight: 700;
}

.documents-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  a {
    text-decoration: none!important;
    color: #333!important;
    font-weight: 500;
    font-size: 1.4375rem;
    word-wrap: break-word;
  }
}

.taa-banner {
  height: 788px;
  width: 100%;
  background-color: #4682b4;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 42px;
  font-weight: 700;
  line-height: 71, 4px;
  align-items: center;
  background-image: url('../../assets/icons/banner-background.png');
  background-position: center center;
}

.taa-content {
  width: 60%;
  text-align: center;
  align-items: center;
  padding-bottom: 0px;
}

.taa-paragraph {
  margin: 15px;
}

.welcome-banner {
  width: 70% !important;
  height: 100%;
  background: #f2f2f2;
  align-items: center;
  margin: 100px auto;
}

.welcome-title {
  color: #003476;
  font-weight: 700;
  font-size: 24px;
  margin-bottom: 50px;
}

.welcome-paragraph {
  font-size: 16px;
  font-weight: 400;
  line-height: 25.6px;
  margin-bottom: 50px;
  text-align: justify;
}

.login-button {
  width: 289px !important;
  height: 62px !important;
  background: #003476 !important;
  border-radius: 8px !important;
  color: #fff !important;
  display: flex !important;
  flex-wrap: wrap !important;
  justify-content: space-between;

  > * {
    margin-top: 10px;
    margin-left: 15px !important;
    margin-right: 15px !important;
  }

  > span {
    margin-top: 15px;
  }
}

.pylwnes {
  height: 453px;
  background: #003476;
  width: 100%;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.pylwnes-title {
  margin-top: 80px;
  margin-bottom: 80px;
  font-size: 24px;
  font-weight: 700;
}

.pylwnes-icons {
  margin-bottom: 50px;
  width: 100%;
}

.first-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.first-row > div {
  width: 30%;
}

.cycle1 {
  margin-bottom: 20px;
}

.cycle2 {
  margin-bottom: 30px;
}

.cycle3 {
  margin-bottom: 23px;
}

.contents-icons-description {
  width: 58%;
  align-items: center;
  display: flex;
  justify-content: center;
}

.second-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.second-row > div {
  margin: 20px;
  display: flex;
  flex-direction: column;
  width: 30%;
}

.contents-icons {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

/*.contents-icons > div {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
}*/

.contents-icons .contents-icons-description{
  font-weight: 700;
  color: #FFFFFF;
  font-size: 1rem;
}

.cycle3 {
  margin-top: 20px;
}

.cycle3-description {
  margin-top: 10px;
}

.landing-page-footer-smart {
  position:relative;
  bottom:0;
  width: 100%;
}

.subFooter {
  background: #f2f2f2;
  position:relative;
  bottom:0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-top: 15rem;
  height: fit-content;
}

.landing-page-footer {
  background: #E7E7E7;
  height: 360px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.footer {
  background: #ffffff;
  border-top: 7px solid #003375;
  position:absolute;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 145px;
}

// .landing-page-footer > * {
//   margin: 30px;
// }

.landing-page-footer p {
  font-size: 16px;
  font-weight: 400;
  color: #003476;
}

.footer-logo {
  cursor: pointer;
}

.taiped-logo-wrapper {
  width: 360px;
  height: 60px;
  display: block;
  overflow: hidden;
}

.taiped-logo {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.foooter-paragraph {
  //width: 60%;
  text-align: center;
}

a:link {
  text-decoration: underline;
}

a:visited {
  text-decoration: none;
  color: #003476;
}

a:hover {
  color: #003476 !important;
  text-decoration: none;
}

a:active {
  text-decoration: underline;
}

.content-title {
  font-size: $size28;
  font-weight: 500;
  margin-bottom: $size4;
}

.content-paragraph {
  margin-top: $size32;
  font-size: $size16;
  text-align: justify;
  line-height: normal;
}

.contents-icons {
  width: 20rem;
  //height: 8rem;
  //margin: 0 1.2rem;
}

.ant-alert-icon {
  width: 100%;
  height: 100%;
}

.ant-alert-description {
  display: flex;
  text-align: center;
  flex-direction: column;
}

.ant-alert-warning {
  border: none !important;
  background-color: white !important;
}

.ant-empty-description,
.ant-alert-description p {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 24px;
  color: $blue2;
  margin-bottom: 24px;
  margin-top: 50px;
}

.ant-alert-description span {
  font-size: 16px;
}

.ant-alert-description img {
  margin-top: 120px;
  height: 320px !important;
}

.ant-alert-description-message {
  display: flex;
  flex-direction: column;
}

/* Mobile devices*/
@media only screen and (min-width: 701px) and (max-width: 991px) {
  // .app-layout__header_landing {
  //   display: flex;
  //   flex-direction: column;
  //   height: 11rem!important;
  // }

  .content-image {
    height: 328px !important;
  }

  .app-layout__header_landing {
    display: flex;
    flex-direction: column;
    .header-right {
      display: flex;
      justify-content: center;
      // flex-direction: column;
      display: flex;
      margin-top: 0.2rem;
      margin-left: 20rem;
    }
  }

  .app-layout__sub_header_landing {
    padding: 0px!important;
    display: flex;
    flex-direction: column;
    height: 100%!important;

    .header-left {
      display: flex;
      justify-content: center;
    }
    
    .header-right {
      display: flex;
      justify-content: center;
  //     transform: scale(0.7);
  // display: flex;
  // margin-top: 0.4rem;
  // margin-left: -2rem;
    }
  }


  .header-padding {
    padding-left: 0px !important;
}

  // .header-right {
  //   margin-bottom: 0.5rem;
  //   display: flex;
  //   flex-direction: row;
  //   align-items: center;
  //   justify-content: center;

  //   .header-links {
  //     display: none;
  //   }

  //   .header-icon-mobile {
  //     display:flex;
  //     justify-content: center;
  //     align-items: center;
  //     color: #046ec5;
  //     font-size: 2.5rem;
  //     margin-top: -0.5rem;
  //   }

  //   .header-links-mobile {
  //     top: 11rem;
  //     line-height: 3.5rem;

  //     .operator-icon {
  //       margin-top: 2rem;
  //       margin-bottom: 1rem;
  //       margin-top: 2rem;
  //       margin-left: auto;
  //       margin-right: auto;
  //     }
      
  //     .header-paragraph {
  //       font-size: 1.25rem;
  //       text-align: center;
  //     }
  //   }
  // }

  .app-layout__logo-gr-democracy {
    margin-left: 60px;
  }
 
  .app-layout__logo-gr-digital-transformation {
    transform: scale(105%);
  }

  .app-layout__logo-gr-2-0 {
    transform: scale(85%);
  }

  .documents-items {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 2rem;
    font-size: 1rem;

    .docs {
      margin: 0px!important;
    }
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) and (max-width: 1119px) {
  .app-layout__header_landing {
    display: flex;

    height: 11rem!important;

  }


  .content-image {
    height: 300px !important;
  }

  .app-layout__sub_header_landing {
    padding: 0px!important;
    // height: 5rem!important;
    display: flex;
    flex-direction: column;
    height: 100%!important;

    .header-right {
      display: flex;
      justify-content: center;
    }
  }

  .documents-items {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 2rem;
    font-size: 1rem;

    .docs {
      margin: 0px!important;
    }
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1280px) {
  // .app-layout__logo-gr-democracy {
  //   margin-left: 10px; //60
  // }

  // .app-layout__logo-gr-2-0 {
  //   margin-right: 10px; //60
  // }

  // .header-links-mobile {
  //   display: none;
  // }
}

@media only screen and (min-width: 1119px) and (max-width: 1281px) {
  

  .header-links-mobile {
    display: none;
  }

  .documents-items {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 2rem;
    font-size: 1rem;

    .docs {
      margin: 0px!important;
    }
  }
}

@media only screen and (min-width: 1281px) and (max-width: 1950px) {
  // .app-layout__sub_header_landing {
  //   & .contents-icons {
  //       margin-left: 3.4rem;
  //     }
  // }
}

@media only screen and (max-width: 700px) {
  .footer {
    height: 250px;
  }
  .badges {
    display: flex;
    flex-direction: column;
  }

  .ant-tooltip {
    width: 80% !important;
  }

  .ant-tooltip-inner {
    min-height: 382px !important;
  }

  .title-white {
    font-size: 20px !important;
  }

  .subtitle-white {
    font-size: 16px !important;
  }

  .ant-btn.applicationButton {
    padding: 0.5rem 0.9rem !important;
  }
  // .staticBannerWrapper{
  //   display: flex !important;
  // flex-direction: column !important;
  // align-items: center !important;
  // justify-content: center !important;
  // text-align: center !important;
  // }

  .content-image {
    height: 328px !important;
  }

  .header-padding {
    padding-left: 0px !important;
}

.app-layout__header_landing {
  display: flex;
  flex-direction: column;
}

.header-right {
  
}

  .main-content {
    margin-top: 1rem;

    &__table  {
      & .ant-table-thead tr th{
        padding: 0.5rem;
      }
    }
  }

  .app-layout__header_landing {
    display: flex;
    height: 9rem!important;
  }

  .app-layout__logo-gr-digital-transformation {
    transform: scale(140%);
  }

  .app-layout__logo-gr-2-0 {
    transform: scale(120%);
  }

  .header-left {
    margin-top: 0.5rem;
    margin-right: 1rem;
    transform: scale(0.8);
  }

  .header-right {
      transform: scale(0.75);
      display: flex;
      margin-top: 0.4rem;
      margin-left: 4rem;

      .header-icon-mobile {
        display:flex;
        justify-content: center;
        align-items: center;
        margin-left: 2rem;
        color: #046ec5;
        font-size: 2.5rem;
        margin-top: -0.5rem;
      }

      .header-links-mobile {
        top: 9rem;
        line-height: 3.5rem;

        .operator-icon {
          margin-top: 2rem;
          margin-bottom: 1rem;
          margin-top: 2rem;
          margin-left: auto;
          margin-right: auto;
        }
        
        .header-paragraph {
          font-size: 1.25rem;
          text-align: center;
        }
      }
  }

  .app-layout__sub_header_landing {
    padding: 0px!important;
    display: flex;
    flex-direction: column;
    height: 100%!important;

    .header-left {
      margin: 0px!important
    }

    .header-right {
      display: flex;
      justify-content: center;
    }
  
    & .contents-icons {
      width: 13rem;
      & .app-layout__logo-digital {
        transform: scale(150%);
        padding-left: 0.8rem;
      }
    }
  }

  .documents-items {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 2rem;
    font-size: 1rem;

    .docs {
      margin: 0px!important;
    }
  }

  .landing-page-footer-smart {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // height: 8.125rem;
    position: relative;
  }
  
  .footer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .taa-banner {
    height: 100%;
    text-align: center;
    font-size: 20px;
    padding: 20px;
    font-weight: 400;
  }

  .taa-icon {
    width: 85px;
  }

  .welcome-banner {
    height: 100%;
    margin-top: 50px;
  }

  .welcome-title {
    text-align: center;
  }

  .welcome-paragraph {
    text-align: justify;
  }

  .pylwnes {
    height: 100%;
    display: flex;
    text-align: center;
  }

  .pylwnes-title {
    font-size: 20px;
  }

  .pylwnes-icons {
    display: flex;
    flex-direction: column;
    align-items: center;
    
  }

  .pylwnes-icons > * {
    display: flex;
    flex-direction: column;
    justify-content: center;
    
  }

  .first-row {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 0px;
  }

  .first-row > div {
    margin: 20px;
    display: flex;
    flex-direction: column;
    width: 30%;
  }

  .second-row {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .second-row > div {
    margin: 20px;
    display: flex;
    flex-direction: column;
    width: 30%;
  }

  .contents-icons {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center; 
  }

  .contents-icons > div {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
  }

  .contents-icons > * {
    text-align: center;
  }

  .landing-page-footer {
    text-align: center;
  }

  .app-layout__header_landing {
    padding: 0px !important;
    margin: 0px !important;
  }

  .app-layout__logo-gr-democracy {
    margin-left: 30px !important;
    width: 90%;
  }

  .app-layout__logo-gr-2-0 {
    //margin-top: 20px !important;
    //margin-left: 20px !important;
    padding-top: 18px;
    margin: 0 !important;
    // padding-left: 40px;
    padding-right: 20px;
    width: 100%;
    cursor: pointer;
  }

  .footer-logo {
    margin: 12px;
    width: 80%;
    cursor: pointer;
  }
}

  .map{
    height: 500px;
    border: 1px solid #1782D5 !important;
    border-radius: 8px !important;
    margin-bottom:5em;
  }


  .input-filter {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px 10px !important;
    background: #ffffff !important;
    border: 1px solid #1782D5 !important;
    box-sizing: border-box;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05) !important;
    border-radius: 8px !important;
    flex: none;
    align-content: left;
    order: 1;
    width: 1516px;
    align-self: stretch;
    flex-grow: 0;
    margin-bottom: 2.5em;
  }

  

  .ant-select{
    width:100%;
  }

  .results-table .ant-table-thead tr th {
    font-weight: normal !important;
    font-size: 12px !important;
    line-height: 0.875rem !important;
    color: #ffffff !important;
    background: #1782D5 !important;
    width: 1516px;
  }

  .ant-input-group-addon {
    display: none!important;
}



.captchaContainer{
  margin-top:5rem;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.warning-message{
  font-size: $size14;
  line-height: 16px;
  color: $grey4;
  margin-bottom: 5rem;
  padding-top: 1rem;
  padding-bottom: 2rem;
  text-align: center;
  background-color:#eef8ee;
}


.instructions{
  font-size: $size14;
  line-height: 14px;
  color: $blue2;
  margin-top: 1rem;
  margin-bottom: 2rem;
  text-align: left;
}

.copyright {
  color: #333;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.title-blue {
  color: #1782D5;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 24px */
}

.title-white {
  color: #FFF;
text-align: center;
font-style: normal;
font-weight: 700;
line-height: 100%; /* 32px */
}

.subtitle-white {
  color: #FFF;
text-align: center;
font-style: normal;
font-weight: 600;
line-height: 100%; /* 32px */
text-decoration-line: underline;
}

.content {
  color: #333;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 38.4px */
  text-align: left;
  padding: 2% 16%;
  padding-bottom: 3%;
  height: fit-content!important;

}

.ant-btn {
&.applicationButton {
  display: flex;
  background-color: #62AE28!important;
  color: #FFFFFF!important;
  border-radius: 8px!important;
  padding: 1rem 1.75rem;
  height: fit-content!important;
  width: fit-content!important;
  margin-right: 6px;
  margin-top: 6px;
  gap: 4px;
  border: 1px solid #62AE28;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  font-size: 1.125rem;
  font-weight: 800;
  line-height: 28px; /* 155.556% */
}}

.contentPadding {
  margin-left: 0px;
  margin-right: auto;
  padding: 0px 16%;
  padding-top: 3%;
}

.header-padding {
  padding-left: 9%;
}

.news-title {
  color: #333;

  font-style: normal;
  font-weight: 600;
  line-height: 130%; /* 26px */
}

.news-date {
  color: #8F8F8F;

font-style: normal;
font-weight: 600;
line-height: 130%; /* 20.8px */
}

.news-text {
  color: #000;

font-style: normal;
font-weight: 400;
line-height: 20px; /* 125% */
}

.see-more {
  color: #333;
cursor: pointer;
font-style: normal;
font-weight: 500;
line-height: 20px; /* 125% */
text-decoration-line: underline;
}

.banner-mb {
  margin-bottom: 10rem;
}

.overlay {
  opacity: 0.8;
background: #003375;
position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.columns-container {
  display: flex;
  justify-content: center; 
  align-items: flex-start; 
}

.panels-round {
  border-radius: 16px !important;
  margin-top: 6.1rem;
}

.content-image {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
}